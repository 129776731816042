// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1cwScvAyebuumKBakOuazc1egulyhXB4",
  authDomain: "sparkpost-dashboard.firebaseapp.com",
  projectId: "sparkpost-dashboard",
  storageBucket: "sparkpost-dashboard.appspot.com",
  messagingSenderId: "1083608044042",
  appId: "1:1083608044042:web:6f576c74a10026547545f3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);