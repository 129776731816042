// action - state management
import * as actionTypes from './actions';

export const initialState = {
    allCampaigns: [],
    campaign: '',
    startDate: '',
    endDate: '',
    data: '',
    DBsnapshot:''
};

//-----------------------|| CAMPAIGN REDUCER ||-----------------------//

const campaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CAMPAIGNS:
            return {
                ...state,
                allCampaigns: action.value
            };
        case actionTypes.SET_DATE:
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate
            };
        case actionTypes.SET_CAMPAIGN:
            return {
                ...state,
                campaign: action.value
            };
        case actionTypes.SET_DATA:
            return {
                ...state,
                data: action.value
            };
            case actionTypes.SET_DB_SNAPSHOT:
            return {
                ...state,
                DBsnapshot: action.value
            };
        default:
            return state;
    }
};

export default campaignReducer;
