import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';
import Loadable from '../ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('../views/authentication/LogIn')));

//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/login']}>
            <Switch location={location} key={location.pathname}>
                <GuestGuard>
                    <Route path="/login" component={AuthLogin} />
                </GuestGuard>
            </Switch>
        </Route>
    );
};

export default LoginRoutes;
