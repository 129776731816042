// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const SET_DATE = 'SET_DATE';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const SET_DATA = "SET_DATA"
export const SET_DB_SNAPSHOT = "SET_DB_SNAPSHOT"