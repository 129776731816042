import React from 'react';
import { DateRangePicker, InputPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useDispatch } from 'react-redux';
import axios from 'axios';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

// project imports
import { SET_CAMPAIGN, SET_DATE, SET_CAMPAIGNS, SET_DB_SNAPSHOT } from '../../../store/actions';
import { gridSpacing } from './../../../store/constant';
import AuthDetails from '../../../views/authentication/AuthDetails';

// style constant
const useStyles = makeStyles((theme) => ({
    boxContainer: {
        width: '200px',
        padding:20,
        marginTop:30,
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },
    pickerBox: {
        zIndex: 999999999
    },
    headerContainer: {
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 20,
        paddingLeft: 20
    }
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = () => {
    const [campaigns, setCampaigns] = React.useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: SET_CAMPAIGN, value: '3DimensionsUSA-Brochure' });
            await axios.get('/db-stats').then((response) => dispatch({ type: SET_DB_SNAPSHOT, value: response.data }));
            await axios.get('/campaigns').then((response) =>
                setCampaigns(
                    response.data.map((campaign) => {
                        dispatch({ type: SET_CAMPAIGNS, value: response });
                        return { label: campaign, value: campaign };
                    })
                )
            );
        };

        fetchData();
    }, []);

    const campaignHandler = (value) => {
        dispatch({ type: SET_CAMPAIGN, value: value });
    };

    const dateHandler = (value1, value2) => {
        dispatch({ type: SET_DATE, startDate: value1, endDate: value2 });
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={8} md={10} sm={12} xs={12}>
                <Grid container spacing={gridSpacing} className={classes.headerContainer}>
                    <Grid item>
                        <InputPicker
                            defaultValue={'3DimensionsUSA-Brochure' }
                            data={campaigns}
                            menuClassName={classes.pickerBox}
                            placeholder={'Choose a campaign'}
                            cleanable={false}
                            size="lg"
                            onSelect={(value) => {
                                campaignHandler(value);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <DateRangePicker
                            menuClassName={classes.pickerBox}
                            menuStyle={{ marginTop: '20px !important' }}
                            placeholder="Select date range"
                            size="lg"
                            onOk={(value) => {
                                dateHandler(value[0], value[1]);
                            }}
                            onClean={() => {
                                dateHandler("", "");
                            }}
                        />
                    </Grid>
                    <Grid item>
                            <AuthDetails />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header;
