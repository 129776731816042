import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from '../../firebase';
import { useDispatch } from 'react-redux';
import { LOGOUT } from '../../store/actions';


const AuthDetails = () => {
    const [authUser, setAuthUser] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }
        });
            return () => {
                listen();
            }
    }, [])

    const userSignOut = () => {
        signOut(auth).then(() => {
            console.log('sign out successful')
            return dispatch({ type: LOGOUT, value: false });
        }).catch(err => console.log(err))
    }

    return (
        <div>{ authUser ? <><p>{`Hello, ${authUser.email}`}</p><button onClick={userSignOut}>Sign Out</button></> : <p></p>}</div>
    )
}

export default AuthDetails;